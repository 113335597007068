import { Title, Image } from "../../../../src/blog/components";
import KOA_TS from "../../../../src/blog/create-koa-server-with-typescript/koa+ts.jpg";
import KOA_ONION_MODEL from "../../../../src/blog/create-koa-server-with-typescript/koa-onion-model.jpg";
import * as React from 'react';
export default {
  Title,
  Image,
  KOA_TS,
  KOA_ONION_MODEL,
  React
};