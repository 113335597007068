import { Title, Image } from "../../../../src/blog/components";
import WEBHOOK from "../../../../src/blog/automaticaly-deploy-with-webhook/webhook-cd.jpg";
import CD_VS_CD from "../../../../src/blog/automaticaly-deploy-with-webhook/cd-vs-cd.png";
import GITHUB_WEBHOOK from "../../../../src/blog/automaticaly-deploy-with-webhook/github-webhook.png";
import * as React from 'react';
export default {
  Title,
  Image,
  WEBHOOK,
  CD_VS_CD,
  GITHUB_WEBHOOK,
  React
};